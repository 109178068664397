<template>
  <ModalWindow :show="show" ref="modal" @cancel="close()" :showBtn="false">
    <div class="contact-form-modal">
      <div class="modal-title heading-3"><span>Füllen Sie die Felder aus.</span>
        <IconButton @click="close" @mouseover="iconName = 'close'" @mouseleave="iconName = 'close-hover'"
          :name="iconName">
        </IconButton>
      </div>
      <ContactForm></ContactForm>
    </div>
  </ModalWindow>
</template>

<script setup>
import ModalWindow from '~/components/ui/ModalWindow.vue';
import ContactForm from '~/components/welcome/ContactForm'
import IconButton from '~/components/ui/IconButton.vue';

const show = ref(false)
const emits = defineEmits(['close'])
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})
const open = () => {
  show.value = true;
}
const close = () => {
  show.value = false;
  emits('close', false)
}
const iconName = ref('close-hover')

watch(
  () => props.isOpen,
  (newV) => { show.value = newV }
)
onMounted(() => {
  show.value = props.isOpen
})

defineExpose({ open, close })

</script>

<style lang="scss" scoped>
.contact-form-modal {

  display: flex;
  flex-direction: column;


  background: #FFFFFF;

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-button {
      position: relative;
      right: 0;
    }
  }

  @media (max-width:991px) {
    padding: 16px 20px;
    width: 100%;
    margin: 0 auto;
    max-width: 520px;
    gap: 24px;
    justify-content: center;
  }

  @media (min-width:992px) {
    padding: 40px;
    gap: 32px;
    width: 860px;
    height: 508px;
    border-radius: 24px;

  }
}
</style>