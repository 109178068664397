<script setup>
const links = computed(() => [
  { to: '/#service-section', text: 'Dienstleistungen' },
  { to: '/#team-section', text: 'Team' },
  { to: '/#work-section', text: 'Arbeiten' },
  { to: '/#qa-section', text: 'Häufig gestellte Fragen' },
  { to: '/#review-section', text: 'Kundenbewertungen' },
  { to: '/#contact-section', text: 'Kontakt' },
])
const props = defineProps({
  opened: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['setOpen'])
const isOpen = ref(false)
function close() {
  isOpen.value = false
  emits('setOpen', isOpen.value)
}
watch(
  () => props.opened,
  (newV) => { isOpen.value = newV }
)
onMounted(() => {
  isOpen.value = props.opened
})
</script>

<template>
  <div class="mobile-nav" :class="{ active: isOpen === true }" @click.self="close()">
    <div class="mobile-nav__content">
      <div class="mobile-nav__links">
        <a v-for="link in links" :key="link.to" :href="link.to" class="mobile-nav__link" @click="close()">
          {{ link.text }}
        </a>
      </div>
      <div class="mobile-nav__actions">
        <BauButton btnSuffix="kostenlos" btnText="Angebot einholen" btnSize="small" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/mobile-nav';
</style>
