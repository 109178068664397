<template>
  <div class="cookie_drawer_option">
    <div class="header">
      <span class="title_content title-1">{{ item.title }}</span>
      <Switcher v-model="status" :disabled="item.disabled" @input="setValue($event)" :value="item.status"/>
    </div>
    <div class="content">
      <span v-if="item.description" class="description_content paragraph normal">{{
          item.description
        }}</span>
    </div>
  </div>
</template>

<script>
import Switcher from "~/components/ui/inputs/Switcher.vue";
import {defineComponent} from "vue";

export default defineComponent({
  components: [Switcher],
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          id: 1,
          status: true,
          disabled: true,
          title: 'Notwendig',
          description:
              'Notwendige Cookies sind erforderlich, um die grundlegenden Funktionen dieser Website zu ermöglichen, wie z. B. die Bereitstellung eines sicheren Logins oder die Anpassung Ihrer Zustimmungseinstellungen. Diese Cookies speichern keine persönlich identifizierbaren Daten.',
        }
      },
    }
  },
  data() {
    return {
      status: false
    }
  },
  methods: {
    setValue(e) {
      this.$emit('input', {
        id: this.item.id,
        status: e
      });
    }
  }
})
</script>

<style lang="scss" scoped>
.cookie_drawer_option {
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;


  }

  .content {
    transition: all 0.3s ease;

    .description_content {
      color: #8A8776;
    }
  }

}
</style>
