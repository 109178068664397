<template>
  <ModalWindow ref="modal" type="drawer" name="cookieDrawer" :show="show">
    <div class="cookie_drawer">
      <Transition name="slide-fade">
        <div class="info_box">
          <div class="top">
            <div class="header">
              <span class="title_content heading-4">Anpassen der Zustimmungseinstellungen</span>
              <span class="description_content paragraph normal">Wir verwenden Cookies, um Ihnen eine effiziente
                Navigation zu
                ermöglichen und bestimmte Funktionen auszuführen. Ausführliche Informationen zu allen Cookies finden Sie
                unter jeder unten aufgeführten Zustimmungskategorie. Die Cookies, die als „Notwendig“ kategorisiert
                sind, werden in Ihrem Browser gespeichert, da sie für die grundlegenden Funktionen der Website
                unerlässlich sind.
              </span>
            </div>
            <div class="middle">
              <div class="options">
                <CookieDrawerOption v-for="(item) in permissions" @input="setPermissionItem($event)" :key="item.id"
                  :item="item" />
              </div>
            </div>
          </div>

          <div class="actions">
            <BauButton btnType="secondary" btnText="Alle ablehnen" @click="declineAll()" />

            <BauButton btnType="secondary" btnText="Meine Einstellungen speichern" @click="close()" />

            <BauButton btnText="Alle akzeptieren" @click="acceptAll()" />
          </div>
        </div>
      </Transition>
    </div>
  </ModalWindow>

</template>

<script>
import BauButton from '~/components/ui/BauButton';
import ModalWindow from '~/components/ui/ModalWindow.vue';
import CookieDrawerOption from '~/components/ui/cookie/CookieDrawerOption.vue';

export default defineComponent({
  components: {
    BauButton,
    ModalWindow,
    CookieDrawerOption
  },
  data() {
    return {
      show: false,
      permissions: [
        {
          id: 1,
          status: true,
          disabled: true,
          title: 'Notwendig',
          description: 'Notwendige Cookies sind erforderlich, um die grundlegenden Funktionen dieser Website zu ermöglichen, wie z. B. die Bereitstellung eines sicheren Logins oder die Anpassung Ihrer Zustimmungseinstellungen. Diese Cookies speichern keine persönlich identifizierbaren Daten.'
        },
        {
          id: 2,
          status: false,
          disabled: false,
          title: 'Statistiken',
          description: 'Statistik-Cookies helfen Website-Betreibern zu verstehen, wie Besucher mit Websites interagieren, indem Informationen anonym gesammelt und gemeldet werden.'
        },
        {
          id: 3,
          status: false,
          disabled: false,
          title: 'Marketing',
          description: 'Marketing-Cookies werden verwendet, um Besucher über Websites hinweg zu verfolgen. Ziel ist es, Anzeigen zu schalten, die für den einzelnen Nutzer relevant und ansprechend sind und damit für Publisher und Drittanbieter wertvoller sind.'
        },
        {
          id: 4,
          status: false,
          disabled: false,
          title: 'Leistung',
          description: 'Analytische Cookies werden verwendet, um zu verstehen, wie Besucher mit der Website interagieren. Diese Cookies helfen dabei, Informationen über Kennzahlen wie die Anzahl der Besucher, Absprungrate, Verkehrsquelle usw. bereitzustellen.'
        },
        {
          id: 5,
          status: false,
          disabled: false,
          title: 'Werbung',
          description: 'Leistungs-Cookies werden verwendet, um die wichtigsten Leistungskennzahlen der Website zu verstehen und zu analysieren, was zur Verbesserung der Benutzererfahrung der Besucher beiträgt.'
        },
        {
          id: 6,
          status: false,
          disabled: false,
          title: 'Nicht kategorisiert',
          description: 'Andere nicht kategorisierte Cookies sind diejenigen, die analysiert werden und noch keiner Kategorie zugeordnet wurden.'
        }
      ],
    }
  },
  methods: {
    setPermissionItem(e) {
      const index = this.permissions.findIndex((item) => item.id === e.id);
      this.permissions[index].status = e.status;
    },
    showOptions(e) {
      this.show = e;
    },
    close() {
      this.show = false;
    },
    acceptAll() {
      this.permissions.forEach((item) => {
        if (item.disabled === false) {
          item.status = true
        }
      })

      localStorage.setItem('accepted', true)
      localStorage.setItem('permissions', JSON.stringify(this.permissions))
    },
    declineAll() {
      this.permissions.forEach((item) => {
        if (item.disabled === false) {
          item.status = false
        }
      })

      localStorage.setItem('accepted', false)
      localStorage.setItem('permissions', JSON.stringify(this.permissions))
    }
  },
  mounted() {
    // this.permissions = JSON.parse(localStorage.getItem('permissions'))
  }
})
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}

.cookie_drawer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  // position: relative;
  overflow: hidden;

  .info_box {
    height: 100vh;
    border-radius: 24px;
    background: #FCFBF5;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
    max-width: 1000px;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 32px 0;

    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;

    /* for Firefox */
    /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }


    .top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        // align-items: center;

        .description_content {
          color: #8A8776;
        }
      }

      .middle {
        display: flex;
        flex-direction: column;
        // overflow-y: scroll;
        position: relative;
        align-items: flex-start;
        width: 100%;

        box-sizing: border-box;
        // max-height: calc(155px * 4);


        .options {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 500px;
          overflow-y: scroll;
          gap: 40px;

          -ms-overflow-style: none;
          /* for Internet Explorer, Edge */
          scrollbar-width: none;

          /* for Firefox */
          /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }

          :deep(.cookie_drawer_option:not(:first-child)) {
            position: relative;

            &::after {
              position: absolute;
              content: '';
              height: 1px;
              width: 100%;
              top: -20px;
              left: 0;
              background: #C1BFB0;
            }
          }

        }


      }
    }


    .actions {
      position: sticky;
      bottom: 0;
      left: 0;
      display: flex;
      gap: 12px;
      padding-bottom: 32px;
      padding-top: 32px;

      // padding: 32px;
      background: #FCFBF5;

      :deep(.btn) {
        flex: 1;
      }
    }

    @media (min-width:992px) {
      transform: translate(92%, 0);
    }

    @media (max-width:991px) {
      width: 100vw;
      border-radius: 0;
      padding: 16px;

      .top {
        .header {
          .description_content {
            display: none;
          }
        }
      }

      .actions {
        flex-direction: column;
      }
    }
  }

}

</style>
