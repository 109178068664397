<template>
  <ModalWindow ref="modal" type="drawer" name="cookieBar" :show="showCookies">
    <div class="cookie_bar">
      <div class="bg" @click="close()"></div>
      <CookieDrawer ref="drawer" :showModal="showCookies" />
      <Transition name="slide-fade">
        <div class="info_box" v-show="showCookies">
          <div class="description">
            <span class="heading-4">
              Wir schätzen Ihre Privatsphäre
            </span>
            <span class="description_content paragraph">
              Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern, personalisierte Werbung oder Inhalte zu bieten
              und
              unseren Datenverkehr zu analysieren. Durch Klicken auf „Alle akzeptieren“ stimmen Sie der Verwendung von
              Cookies zu.
              <a href="/cookie-richtlinie"><b>Cookie-Richtlinie</b></a>
            </span>
          </div>
          <div class="actions">
            <BauButton btnType="secondary" btnText="Anpassen" @click="openDrawer()" />
            <BauButton btnType="secondary" btnText="Alle ablehnen" @click="declineAll()" />
            <BauButton btnText="Alle akzeptieren" @click="acceptAll()" />
          </div>
        </div>
      </Transition>
    </div>
  </ModalWindow>


</template>

<script>
export default defineComponent({
  mounted() {
    if (localStorage.getItem('accepted') === 'true') {
      this.showCookies = false;
    }
  },
  data() {
    return {
      showCookies: true,
      showOptions: false,
    }
  },
  methods: {
    close() {
      this.showCookies = false;
      this.show = false;
      localStorage.setItem('show_cookies', 'false');
    },
    openDrawer() {
      this.$refs.drawer.showOptions(true);
    },
    acceptAll() {
      localStorage.setItem('accepted', 'true');
      localStorage.setItem('show_cookies', 'false');
      this.showCookies = false;
      this.show = false;
    },
    declineAll() {
      localStorage.setItem('accepted', 'false');
      localStorage.setItem('show_cookies', 'false');
      this.showCookies = false;
      this.show = false;
    }
  }
})
</script>


<style lang="scss" scoped>
.cookie_bar {
  // position: fixed;
  // bottom: 0;
  // margin: 0 20px;
  width: 100%;
  height: 100%;
  z-index: 99;
  box-sizing: border-box;

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(100px);
    opacity: 0;
  }

  .info_box {
    position: absolute;
    bottom: 20px;
    left: 0;
    margin: 0 20px;
    background: white;

    display: grid;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    box-shadow: 0 0 36px rgba(0, 0, 0, 0.24);

    width: calc(100% - 40px);
    gap: 32px;
    padding: 32px;
    border-radius: 24px;

    .description {
      display: flex;
      flex-direction: column;
      gap: 4px;

      span {
        text-align: start;
      }

      .cookies-link {
        cursor: pointer;
        border-color: transparent;
        background: transparent;
        box-sizing: border-box;
        height: auto;

        position: relative;

        &::after {
          position: absolute;
          content: '';
          bottom: -1px;
          left: 0;
          border-bottom: 1px dashed;
          color: #fff;
          background-color: #fff;
          height: 1px;
          width: 100%;

        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    @media (max-width:991px) {
      bottom: 10px;
      margin: 0 10px;
      width: calc(100% - 20px);
      gap: 24px;
      .description{
        gap: 12px;
      }

      .actions {
        flex-direction: column;
      }
      :deep(.actions){
        .btn{
          width: 100%;
        }
      }
    }
  }
}
</style>
