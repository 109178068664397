<script setup lang="ts">
import MobileNav from './MobileNav.vue';


const navLinks = computed(() => [
  { to: '/#service-section', text: 'Dienstleistungen' },
  { to: '/#team-section', text: 'Team' },
  { to: '/#work-section', text: 'Arbeiten' },
  { to: '/#qa-section', text: 'Häufig gestellte Fragen' },
  { to: '/#review-section', text: 'Kundenbewertungen' },
  { to: '/#contact-section', text: 'Kontakt' },
])
const isOpen = ref(false)
const iconName = ref('menu')
const isBodyScrolled = useIsBodyScrolled()

function toggle() {
  isOpen.value = !isOpen.value
}

watch(() => isOpen.value,
  (newV) => { iconName.value = newV ? 'close' : 'menu' },
  { deep: true })
const emits = defineEmits(['showModal'])

</script>

<template>
  <header class="header" :class="{ 'body-scrolled': isBodyScrolled }">
    <div class="container">
      <div class="header__content">
        <a href="/" class="logo">
          <img src="/img/logo.png" width="75" height="50" alt="logo" />
        </a>
        <nav class="header__nav">
          <a v-for="link in navLinks" :key="link.to" :href="link.to" @click="isOpen = false"
            class="header__nav-item title-4">
            {{ link.text }}
          </a>
        </nav>
        <BauButton btnSuffix="kostenlos" btnText="Angebot einholen" btnSize="small" class="header__cta-button"
          @click="emits('showModal', true)" />

        <IconButton class="header__mobile-nav-toggler hamburger hamburger--slider" :name="iconName"
          :class="{ 'is-active': isOpen }" type="button" @click="toggle()" />
      </div>
    </div>
    <MobileNav :opened="isOpen" @setOpen="isOpen = $event" />
  </header>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/header';
</style>