<script setup>
import ContactFormModal from './components/welcome/ContactFormModal.vue';
useHead({
  htmlAttrs: {
    lang: 'ro'
  }
})
const showModal = ref(false)
</script>

<template>
  <div class="_app" id="app">
    <SvgIcons />
    <TheHeader @showModal="showModal = $event" />
    <CookieBar></CookieBar>
    <ContactFormModal ref="contactModal" :isOpen="showModal" @close="showModal = $event" />

    <main class="main">
      <NuxtPage />
    </main>
  </div>
</template>

<style lang="scss">
@use 'assets/scss/main';
</style>