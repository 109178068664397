<template>
  <button class="icon-button" @click="emits('click')" @mouseover="emits('mouseover')" @mouseleave="emits('mouseleave')">
    <SvgIcon :name="iconName" />
  </button>
</template>

<script setup>
import SvgIcon from '~/components/ui/SvgIcon.vue';
const props = defineProps({
  name: {
    type: String,
    default: 'close-hover',
  }
})
const iconName = ref('close-hover')

watch(() => props.name,
  (newV) => { iconName.value = newV },
  { deep: true })

onMounted(() => {
  iconName.value = props.name
})

const emits = defineEmits(['click', 'mouseover', 'mouseleave'])
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/icon-button.scss'
</style>