<script setup lang="ts">
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

interface Props {
  type?: 'text' | 'number' | 'email' | 'password' | 'phone'
  name?: string
  placeholder?: string
  modelValue: string | number
  hint?: string
  rightText?: string
  description?: string
  white?: boolean
  disabled?: boolean
  readonly?: boolean
  required?: boolean,
  isError?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  type: 'text',
  placeholder: '',
  hint: '',
  rightText: '',
  description: '',
  white: false,
  disabled: false,
  readonly: false,
  required: true,
  isError: false
})
const emits = defineEmits(['update:modelValue', 'phoneValidation'])

function validatePhone(e: { valid: boolean; nationalNumber: string; countryCallingCode: string; }) {

  emits('phoneValidation', {
    isPhoneValid: e.valid,
    formatedPhone: {
      number: e.nationalNumber,
      prefix: e.countryCallingCode,
    }
  })
}

const value = defineModel<string | number>()
onMounted(() => {
  value.value = props.modelValue
})
</script>

<template>
  <div class="input">
    <span v-if="name" class="input__name">{{ name }}</span>
    <vue-tel-input v-if="type === 'phone'"
                   class="input__field-wrapper"
                   :class="{ white, disabled, isError }"
                   v-model="value" @validate="validatePhone($event)"
                   :autoFormat="true"
                   :validCharactersOnly="true"
                   id="phone"
                   :inputOptions="{
                      showDialCode: true,
                      maxLength: 25,
                      placeholder: placeholder,
                      styleClasses: 'input__field'
                    }"
                   :mode="'international'"/>
    <span v-else class="input__field-wrapper" :class="{ white, disabled, isError }">

      <input v-model="value" :type="type" class="input__field" :placeholder="placeholder" :disabled="disabled"
             :readonly="readonly" @input="$emit('update:modelValue', $event.target.value)"/>
      <!-- <span v-if="hint" class="input__hint">
        <SvgIcon name="info" class="input__hint-trigger" />
        <span class="input__hint-content">{{ hint }}</span>
      </span> -->
      <span v-if="rightText" class="input__right-text">{{ rightText }}</span>
    </span>
    <span v-if="description" class="input__description">{{ description }}</span>
    <span v-if="isError" class="input__description required">Pflichtfeld ausfüllen</span>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/input';
</style>
