<script setup lang="ts">


const emit = defineEmits(['cancel', 'transition:end'])

interface Props {
  name?: string
  type?: 'drawer' | 'modal',
  show?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  type: 'modal',
  show: false,
})

const isOpen = ref(false)
function open() {
  isOpen.value = true
}

function close() {
  emit('cancel')
  isOpen.value = false
}
defineExpose({
  open, close
})
watch(
  () => props.show,
  (newV) => {
    isOpen.value = newV
  },
  { deep: true }
)
onMounted(() => {
  isOpen.value = props.show
})
</script>

<template>
  <div class="modal" :class="[{ 'active': isOpen === true }, { 'modal--drawer': props.type === 'drawer' }]"
    @transitionend.self="emit('transition:end', isOpen)" @click.self="close">
    <div class="modal__content "
      :class="{ 'modal__content--drawer': props.type === 'drawer'}"
      @click.self="close">

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/scss/components/modal';
</style>