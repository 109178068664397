<template>
  <div class="contact-form">
    <InputField :isError="errors.firstname" v-model="form.firstname" type="string" placeholder="Vorname*"/>
    <InputField :isError="errors.lastname" v-model="form.lastname" type="string" placeholder="Nachname*"/>
    <InputField :isError="errors.phoneNumber" v-model="form.phoneNumber" type="phone" placeholder="Telefonnummer*"
                @validate-phone="getPhoneError()"/>
    <BauButton :btnText="btnText" @click="submit()"/>
    <CheckboxInput v-model="form.isAccepted" :checkClass="checkClass">
      Mit dem Absenden des Formulars erkläre ich mich einverstanden mit den
      <a href="/datenschutzrichtlinie">Datenschutzerklärung</a>
    </CheckboxInput>
  </div>
  <SuccessFormModal ref="successModal" :isOpen="openModal" @close="openModal = $event"></SuccessFormModal>
</template>
<script setup>
import InputField from '~/components/ui/InputField'
import BauButton from '~/components/ui/BauButton'
import CheckboxInput from '~/components/ui/CheckboxInput'
import SuccessFormModal from '~/components/welcome/SuccessFormModal'

const props = defineProps({
  btnText: {
    type: String,
    default: 'Beratung anfordern'
  },
  checkClass: {
    type: String,
    default: ""
  }
})
const form = ref({
  firstname: null,
  lastname: null,
  phoneNumber: '49',
  isAccepted: false
})
const {$axios} = useNuxtApp();
const errors = ref({
  firstname: false,
  lastname: false,
  phoneNumber: false,
})
const openModal = ref(false)
const areErrors = computed(() => {

  return Object.values(errors.value).some(value => {
    value === true
  })
})

function getPhoneError(e) {
  errors.value.phoneNumber = !e.isPhoneValid
}

const submit = () => {
  errors.value = {
    firstname: false,
    lastname: false,
    phoneNumber: false,
  };
  const phoneParts = form.value.phoneNumber.split(' ');
  const prefix = phoneParts[0];
  const phone = phoneParts.slice(1).join('').replace(/\s+/g, ''); // Rejoin the rest of the phone number without the prefix
  $axios.post('/v2/order', {
    company_id: 5,
    first_name: form.value.firstname,
    phone: phone,
    prefix: prefix,
  }, {
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }).then((response) => {
    if (response.status === 201) {
      open()
      resetForm();
    }
  }).catch((error) => {
    error.response.data.errors ? errors.value = {
      firstname: error.response.data.errors.first_name,
      lastname: error.response.data.errors.last_name,
      phoneNumber: error.response.data.errors.phone
    } : errors.value = {
      firstname: false,
      lastname: false,
      phoneNumber: false
    };
  });

}

function resetForm() {
  form.value = {
    firstname: null,
    lastname: null,
    phoneNumber: '49',
    isAccepted: false
  };
}

function open() {
  openModal.value = true
}

</script>
<style lang="scss" scoped>
.contact-form {
  display: grid;
  gap: 12px;
}
</style>
