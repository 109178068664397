<template>
  <ModalWindow ref="modal" :show="show" :showBtn="false">
    <div class="modal-content">
      <span class="modal-title heading-4"> Innerhalb der nächsten 48 Stunden wird sich unser Vertreter mit Ihnen in
        Verbindung setzen</span>
      <span class="modal-description title-3">Im Rahmen dieses Gesprächs werden wir weitere Details besprechen, um Ihnen
        ein auf Ihre Bedürfnisse zugeschnittenes Angebot zu erstellen</span>
      <BauButton btnText="Schließen" @click="close()" />
    </div>

  </ModalWindow>
</template>

<script setup>
import ModalWindow from '~/components/ui/ModalWindow.vue';
import BauButton from '~/components/ui/BauButton.vue'

const show = ref(false)
const emits = defineEmits(['close'])
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})
const open = () => {
  show.value = true;
}
const close = () => {
  show.value = false;
  emits('close', false)
}
defineExpose({
  open, close
})
watch(
  () => props.isOpen,
  (newV) => { show.value = newV }
)
onMounted(() => {
  show.value = props.isOpen
})
</script>

<style lang="scss" scoped>
.modal-content {
  /* Frame 111 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;


  /* Black & White/White */
  background: #FFFFFF;


  .modal-description {
    margin-top: 12px;
    margin-bottom: 56px;
  }

  .modal-description,
  .modal-title {
    text-align: center
  }

  @media(max-width:991px) {
    padding: 24px;
    height: auto;
    width: calc(100vw - 24px);
    margin: 0 auto;
    max-width: 520px;

    .modal-description {
      margin-bottom: 40px;
    }
  }

  @media(min-width:992px) {
    padding: 80px;
    width: 860px;
    height: 396px;

  }
}
</style>