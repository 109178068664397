<template>
	<div class="toggle_switch">
		<span v-if="false_text" class="toggle_false_text">{{
			false_text
		}}</span>
		<span class="toggle-wrapper" :class="[{ disabled: disabled }, { active: value }]" role="checkbox"
			@click="toggle()" @keydown.space.prevent="toggle()">
			<span class="toggle-background"></span>
			<span class="toggle-indicator" :style="indicatorStyles"></span>
		</span>
		<span v-if="true_text" class="toggle_true_text">{{ true_text }}</span>
	</div>
</template>

<script setup>
const props = defineProps({
	value: {
		type: Boolean,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	false_text: {
		type: String,
		default: '',
	},
	true_text: {
		type: String,
		default: '',
	},
})
const emits = defineEmits(['input'])
const backgroundStyles = computed(() => {
	if (props.disabled === true) {
		return {
			disabled_true_active: props.value === true,
			disabled_true_inactive: props.value === false,
		};
	} else {
		return {
			'gold-mid': props.value === true,
			'gray-lighter': props.value === false,
		};
	}
})

const indicatorStyles = computed(() => {
	return {
		transform: props.value ? 'translateX(20px)' : 'translateX(0)',
	};
})
const toggle = () => {
	if (props.disabled === false) {
		emits('input', !props.value);
	}
}
defineExpose({ toggle })
</script>

<style lang="scss" scoped>
.toggle_switch {
	display: flex;
	align-items: center;
	gap: 12px;

	.toggle_false_text {
		// font-family: $base-font-family;
		// @include font(400, normal, 16px, 20px, 0.01em);
		// font-feature-settings: 'pnum' on, 'lnum' on;
		// color: $primary-dark-grey;
	}

	.toggle_true_text {
		// font-family: $base-font-family;
		// @include font(400, normal, 16px, 20px, 0.01em);
		// font-feature-settings: 'pnum' on, 'lnum' on;
		// color: $primary-dark-grey;
	}

	.gold-mid {
		// background-color: $primary-green;
	}

	.gray-lighter {
		// background-color: $light-grey-500;
	}

	.active {
		background-color: #F1BD03;

	}

	.disabled_true_active {
		// background-color: $green-500;
	}

	.disabled_true_inactive {
		// background-color: $ocean-300;
	}

	.toggle-wrapper {
		display: inline-block;
		position: relative;
		cursor: pointer;
		width: 50px;
		height: 30px;
		border-radius: 99px;

		&.active {
			background-color: #F1BD03;
		}

		&.disabled {
			opacity: .5;
			cursor: no-drop;
		}
	}

	.disabled {
		cursor: no-drop;
	}

	.toggle-wrapper:focus {
		outline: 0;
	}

	.toggle-background {
		display: inline-block;
		border-radius: 99px;
		height: 100%;
		width: 100%;
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
		transition: background-color 0.4s ease;
	}

	.toggle-indicator {
		position: absolute;
		height: 28px;
		width: 28px;
		left: 1px;
		bottom: 1px;
		background-color: #ffffff;
		border-radius: 9999px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		transition: transform 0.4s ease;
	}
}
</style>
