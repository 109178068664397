import { default as cookie_45richtliniemtYHNuV0PfMeta } from "/opt/build/repo/pages/cookie-richtlinie.vue?macro=true";
import { default as datenschutzrichtlinieieB7fKsnC0Meta } from "/opt/build/repo/pages/datenschutzrichtlinie.vue?macro=true";
import { default as impressumkupBJR4Zb5Meta } from "/opt/build/repo/pages/impressum.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
export default [
  {
    name: "cookie-richtlinie",
    path: "/cookie-richtlinie",
    component: () => import("/opt/build/repo/pages/cookie-richtlinie.vue").then(m => m.default || m)
  },
  {
    name: "datenschutzrichtlinie",
    path: "/datenschutzrichtlinie",
    component: () => import("/opt/build/repo/pages/datenschutzrichtlinie.vue").then(m => m.default || m)
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/opt/build/repo/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  }
]