export const useIsBodyScrolled = () => {
  const bodyScrolled = ref(false)

  function onScroll() {
    bodyScrolled.value = window.scrollY > 0
  }

  if(process.client) {
    window.addEventListener('scroll', onScroll)
  }

  onUnmounted(() => {
    if(process.client) {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return bodyScrolled
}