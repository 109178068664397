<template>
    <a v-if="isLink" :href="btnLink" target="_blank" class="btn" :type="actionType" @mouseover="emits('mouseover')"
        @mouseleave="emits('mouseleave')" @click="emits('click')"
        :class="[btnType, btnSize, { lightMode: lightMode }, { disabled: isDisabled }]">

        <SvgIcon v-if="withIcon && icon.position === 'left'" :name="iconName" />

        <div class="btn-text">
            <span class="text-content" :class="btnSize === 'small' ? 'title-4' : 'title-2'"> {{ btnText }}
            </span>
            <div class="divider" v-if="btnSuffix"></div>
            <span class="text-content normal" v-if="btnSuffix" :class="btnSize === 'small' ? 'default' : 'title-3'">
                {{ btnSuffix }} </span>
        </div>

        <SvgIcon v-if="withIcon && icon.position === 'right'" :name="iconName" />
    </a>
    <button v-else class="btn" :type="actionType" @mouseover="emits('mouseover')" @mouseleave="emits('mouseleave')"
        @click="emits('click')" :class="[btnType, btnSize, { lightMode: lightMode }, { disabled: isDisabled }]">

        <SvgIcon v-if="withIcon && icon.position === 'left'" :name="iconName" />

        <div class="btn-text">
            <span class="text-content" :class="btnSize === 'small' ? 'title-4' : 'title-2'"> {{ btnText }}
            </span>
            <div class="divider" v-if="btnSuffix"></div>
            <span class="text-content normal" v-if="btnSuffix" :class="btnSize === 'small' ? 'default' : 'title-3'">
                {{ btnSuffix }} </span>
        </div>

        <SvgIcon v-if="withIcon && icon.position === 'right'" :name="iconName" />
    </button>
</template>
<script setup>
import SvgIcon from '~/components/ui/SvgIcon'
const props = defineProps({
    btnType: {
        type: String,
        default: 'primary', // secondary
    },
    actionType: {
        type: String,
        default: 'button', // submit
    },
    btnSize: {
        type: String,
        default: 'default', // small
    },
    lightMode: {
        type: Boolean,
        default: false
    },
    btnText: {
        type: String,
        default: 'Button'
    },
    isDisabled: {
        type: Boolean,
        default: false
    },
    btnSuffix: {
        type: String,
        required: false
    },
    withIcon: {
        type: Boolean,
        default: false
    },
    isLink: {
        type: Boolean,
        default: false
    },
    btnLink: {
        type: String,
        default: '',
        ref
    },
    icon: {
        type: Object,
        default() {
            return {
                position: 'left', //right
                name: 'arrow_up',
            }
        }
    },
})
const iconName = ref('')
watch(() => props.icon,
    (newV) => { iconName.value = newV.name },
    { deep: true })

const emits = defineEmits(['click', 'mouseover', 'mouseleave'])
onMounted(() => {
    iconName.value = props.icon.name
})

</script>